import { graphql } from "gatsby"
import React from "react"
import {
  PageContainer,
  PageMain,
  PageSidebar,
  PageFooter,
  PageOuterSidebar,
} from "../assemblies/pageTemplate"
import { PageProps, BlogContext } from "../types"
import { Stack } from "../layout/stack"
import { MarkdownCard } from "../assemblies/card"
import { PageHeader, PageTitle, PageMeta } from "../assemblies/pageHeader"
import { tagListing } from "../elements/tag"
import { SecondaryNav } from "../assemblies/nav"

const BlogTemplate: React.FC<PageProps<GatsbyTypes.BlogQuery, BlogContext>> = ({
  data: { allMarkdownRemark, tagsGroup },
  pageContext: { seriesInfo },
  path,
}) => {
  const tagSiblings = tagsGroup.group ? tagListing(tagsGroup.group) : []

  return (
    <PageContainer>
      <PageOuterSidebar>
        <PageSidebar>
          <SecondaryNav parents={[]} siblings={tagSiblings} activePath={path} />
        </PageSidebar>
        <PageMain>
          <PageHeader>
            <PageTitle title="Blog" />
            <PageMeta description="All articles on Specific Solutions" />
          </PageHeader>
          {allMarkdownRemark.edges.length > 0 ? (
            <Stack gap={1}>
              {allMarkdownRemark.edges.map(({ node }) => (
                <MarkdownCard
                  remark={node}
                  key={node.id}
                  seriesInfo={
                    node.frontmatter?.path
                      ? seriesInfo[node.frontmatter.path]
                      : undefined
                  }
                />
              ))}
            </Stack>
          ) : null}
        </PageMain>
      </PageOuterSidebar>
      <PageFooter />
    </PageContainer>
  )
}

export default BlogTemplate

export const query = graphql`
  query Blog {
    allMarkdownRemark(
      filter: { frontmatter: { type: { in: ["series", "article", null] } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...MarkdownNode
        }
      }
    }
    tagsGroup: allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
